import React, {useEffect, useRef, useState} from 'react';
import {axiosPrivate} from "../../api/axios";
import Snackbar from "@mui/material/Snackbar";
import BlockUi from "react-block-ui";
import Alert from "@mui/material/Alert";
import ThreeDSChallenge from "../ThreeDSChallenge";
import {Box, Button, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


import visaLogo from "../../assets/images/payments/visa logo.png";
import mcLogo from '../../assets/images/payments/mc logo.svg';
import amexLogo from '../../assets/images/payments/amex logo.png';

const MINIMUM_TOPUP_AMOUNT = process.env.MINIMUM_TOPUP_AMOUNT
const WEBX_PAY_SESSION_URL = process.env.WEBX_PAY_SESSION_URL;
const WEBX_PAY_SESSION_VERSION = process.env.WEBX_PAY_SESSION_VERSION;
const WEBX_PAY_BANK_MID = process.env.WEBX_PAY_BANK_MID;
const WEBX_PAY_AMEX_SESSION_URL = process.env.WEBX_PAY_SESSION_URL;
const WEBX_PAY_AMEX_SESSION_VERSION = process.env.WEBX_PAY_SESSION_VERSION;
const WEBX_PAY_AMEX_BANK_MID = process.env.WEBX_PAY_BANK_MID;

const AddCardForm = ({
                         user,
                         setAddNewCard,
                         paymentGateway,
                         checked,
                         amount,
                         lotteryName,
                         subscriptionRequest,
                         saveCard,
                         tokenization,
                         selectedCardId,
                         paymentMethod,
                         setSelectCardType,
                         tickets,
                         setBuyBlocking,
                         topUpModal
                     }) => {

    const [cardDetails, setCardDetails] = useState({
        'cardholder-name': '',
        'card-number': '',
        'card-prefix': '',
        'card-postfix': '',
        'security-code': '',
        'expiry-month': '',
        'expiry-year': '',
    });
    const [errMsg, setErrMsg] = useState('')
    const [cardType, setCardType] = useState(null)
    const [blocking, setBlocking] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [severity, setSeverity] = useState('')
    const [sessionID, setSessionID] = useState(null)
    const [show3DS, setShow3DS] = useState(false);
    const [success, setSuccess] = useState(false)
    const [recPayment, setRecPayment] = useState([])
    const [creq, setCreq] = useState("");
    const [actionUrl, setActionUrl] = useState("")
    const [errors, setErrors] = useState({
        cardNumber: '',
        expiryMonth: '',
        expiryYear: '',
        securityCode: '',
    });

    console.log('save', saveCard)

    const formRef = useRef(null);

    useEffect(async () => {
        if (cardType) {
            loadScript(cardType);

            setBlocking(true);
            try {
                const response = await axiosPrivate.post('proxy/session', {
                    'card_type': cardType,
                    'saveCard': saveCard,
                    'tokenization': tokenization,
                    'session': {"authenticationLimit": 25}
                });
                console.log('session', response.data.session.id);
                setSessionID(response.data.session.id);
            } catch (error) {
                console.log(error);
            }

            setTimeout(() => {
                initHostedSession();
            }, 2000);
        }
        return () => {
            removeScript(cardType);
        };
    }, [cardType]);

    const loadScript = (type: string) => {
        removeScript(type);

        const script = document.createElement('script');
        script.async = true;

        if (type === 'mastercard' || type === 'visa') {
            script.src = `${WEBX_PAY_SESSION_URL}version/${WEBX_PAY_SESSION_VERSION}/merchant/${WEBX_PAY_BANK_MID}/session.js`
        } else if (type === 'amex') {
            script.src = `${WEBX_PAY_AMEX_SESSION_URL}version/${WEBX_PAY_AMEX_SESSION_VERSION}/merchant/${WEBX_PAY_AMEX_BANK_MID}/session.js`
        }

        script.onload = () => console.log(`${type} script loaded.`);
        script.onerror = () => console.error(`Failed to load ${type} script.`);

        document.body.appendChild(script);
    };

    const removeScript = (type: string) => {
        const scripts = document.querySelectorAll(`script[src*="gateway.mastercard.com/form/version"]`);
        scripts.forEach((script) => script.remove());
    };

    useEffect(async () => {
        if (cardType) {
            setErrors({
                cardNumber: '',
                expiryMonth: '',
                expiryYear: '',
                securityCode: '',
            })
            loadScript(cardType);
            setBlocking(true);
            setTimeout(() => {
                initHostedSession();
            }, 2000);
        }
        return () => {
            removeScript(cardType);
        };
    }, [cardType]);

    const handleInputChange = (e) => {
        const {id, value} = e.target;
        setCardDetails((prevDetails) => ({
            ...prevDetails,
            [id]: value,
        }));
    };

    const handleCardTypeChange = (cardType) => {
        setCardDetails((prevDetails) => ({
            ...prevDetails,
            cardType,
        }));
    };

    const handleMonthChange = (e) => {
        const month = e.target.value;
        setCardDetails((prevDetails) => ({
            ...prevDetails,
            month,
        }));
    };

    const handleYearChange = (e) => {
        const year = e.target.value;
        setCardDetails((prevDetails) => ({
            ...prevDetails,
            year,
        }));
    };

    const handleAlertClose = () => {
        setOpenAlert(false)
    }

    const initHostedSession = () => {
        setBlocking(true)
        window.PaymentSession.configure({
            session: sessionID,
            fields: {
                card: {
                    number: "#card-number",
                    securityCode: "#security-code",
                    expiryMonth: "#expiry-month",
                    expiryYear: "#expiry-year",
                },
            },
            frameEmbeddingMitigation: ["javascript"],
            callbacks: {
                initialized: function (response) {
                    console.log('session.js initialized')
                    console.log(response)
                    if (response.status === 'ok') {
                        setBlocking(false)
                    }
                },
                formSessionUpdate: async function (response) {
                    setBlocking(true)
                    setErrors({})
                    // HANDLE RESPONSE FOR UPDATE SESSION
                    console.log('session.js formSessionUpdate')
                    console.log(response)
                    if (response.status) {
                        if ("ok" == response.status) {
                            console.log("Session updated with data: " + response.session.id);
                            //check if the security code was provided by the user
                            if (response.sourceOfFunds.provided.card.securityCode) {
                                console.log("Security code was provided.");
                            }
                            //check if the user entered a Mastercard credit card
                            if (response.sourceOfFunds.provided.card.scheme == 'MASTERCARD') {
                                console.log("The user entered a Mastercard credit card.")
                            }
                        } else if ("fields_in_error" == response.status) {
                            console.log("Session update failed with field errors.");
                            if (response.errors.cardNumber) {
                                console.log("Card number invalid or missing.");
                                setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    cardNumber: "Card number invalid or missing.",
                                }));
                            }
                            if (response.errors.expiryYear) {
                                console.log("Expiry year invalid or missing.");
                                setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    expiryYear: "Expiry year invalid or missing.",
                                }));
                            }
                            if (response.errors.expiryMonth) {
                                console.log("Expiry month invalid or missing.");
                                setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    expiryMonth: "Expiry month invalid or missing.",
                                }));
                            }
                            if (response.errors.securityCode) {
                                console.log("Security code invalid.");
                                setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    securityCode: "CVV must be exactly 3 digits.",
                                }));
                            }
                            setBlocking(false);
                            return
                        } else if ("request_timeout" == response.status) {
                            console.log("Session update failed with request timeout: " + response.errors.message);
                        } else if ("system_error" == response.status) {
                            console.log("Session update failed with system error: " + response.errors.message);
                        }
                    } else {
                        console.log("Session update failed: " + response);
                    }

                    console.log('sessionID', sessionID)

                    let data = JSON.stringify({
                        "sessionID": sessionID,
                        "u-id": user.id,
                        "u-email": user.email,
                        "response": response,
                    });
                    handleSubmit(data)
                }
            },
            interaction: {
                displayControl: {
                    formatCard: "EMBOSSED",
                    invalidFieldCharacters: "REJECT"
                }
            }
        });
    }

    const handleSubmit = async (data) => {
        if (selectedCardId === 0) {
            handleAddTransaction(data)
        } else {
            try {
                setBlocking(true)
                const response = await axiosPrivate.post('user/card/save', data)
                handle3Ds(response)
            } catch (err) {
                console.error("Error fetching 3DS data:", err);
            }
        }
    };

    const handle3Ds = (response) => {
        const parser = new DOMParser();

        const htmlDoc = parser.parseFromString(response.data["3ds_iframe_body"], "text/html");


        const form = htmlDoc.querySelector("form");
        const action = form?.getAttribute("action");

        const creqInput = form?.querySelector("input[name='creq']");
        const creqValue = creqInput?.getAttribute("value");

        console.log('creqValue', creqValue)
        console.log('creqValue', action)

        setActionUrl(action || "");
        setCreq(creqValue || "");

        setBlocking(false)
        setShow3DS(true);
    }

    const handleAddTransaction = async (card) => {
        try {
            console.log("amount", amount)
            setBlocking(true)
            if (
                amount == undefined ||
                parseFloat(amount).toFixed(2) < parseInt(MINIMUM_TOPUP_AMOUNT)
            ) {
                setErrorMsg(
                    'Entered Topup Amount Error.The minimum topup amount is : ' +
                    MINIMUM_TOPUP_AMOUNT
                )
                setBlocking(false)
                setError(true)
                return
            }
            console.log('genie Topup ammount', amount)

            console.log('pt1', selectedCardId)


            const data = {
                payment_type: paymentGateway,
                card_id: selectedCardId,
                payment_method: paymentMethod,
                checked: checked,
                save_card: saveCard,
                amount: amount,
                tokenization: tokenization,
                lottery_name: lotteryName,
                tickets_request:
                    Object.keys(tickets).length > 0 ? JSON.stringify(tickets) : null,
                subscription_request:
                    Object.keys(subscriptionRequest).length > 0
                        ? JSON.stringify(subscriptionRequest)
                        : null,
                card_details: JSON.parse(card).response,
            }

            console.log(data)

            debugger;
            const response = await axiosPrivate.post(
                'user/transaction/add/' + user.id, data
            )

            handle3Ds(response)

            // const transaction = response?.data?.transaction
            // const ticketdata = response?.data?.ticket_data
            // const subscriptiondata = response?.data?.subscription_data
            //
            // if (Object.keys(tickets).length > 0) {
            //     handleBuyTickets(ticketdata, transaction)
            // } else if (Object.keys(subscriptionRequest).length > 0) {
            //     handleBuyTickets(subscriptiondata, transaction)
            // } else {
            //     setSuccess(true)
            //     setRecPayment(transaction)
            // }
        } catch (err) {
            setBlocking(false)
            if (!err?.response) {
                setErrMsg('No Server Response')
            } else if (err.response?.status === 422) {
                if (Array.isArray(err.response.data?.errors))
                    setErrMsg(err.response.data?.errors[0])
                else setErrMsg(err.response.data?.errors)
            } else {
                setErrMsg(err.response.data?.errors?.message)
            }
        }
    }

    const closeModal = () => {
        setShow3DS(false);
        window.location.reload();
    };

    const closeAddCardModal = () => {
        setBuyBlocking(false)
        if (setSelectCardType) {
            setSelectCardType(null)
        } else {
            setAddNewCard(false);
        }
    };


    return (
        <div ref={formRef}>
            {show3DS &&
                <ThreeDSChallenge show3DS={show3DS} creq={creq} saveCard={saveCard} actionUrl={actionUrl}
                                  closeModal={closeModal}/>
            }
            <BlockUi tag="div" blocking={blocking}>
                <div className="row card-wrapper">
                    <div className="card-form">
                        <div className="card-list">
                            <div className="card-item">
                                <div className="card-item__side -front" style={{position: "relative"}}>
                                    <IconButton
                                        onClick={closeAddCardModal}
                                        sx={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "10px",
                                            backgroundColor: "red",
                                            color: "white",
                                            borderRadius: "50%",
                                            width: "30px",
                                            height: "30px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontSize: "16px",
                                            zIndex: "100",
                                            '&:hover': {
                                                backgroundColor: "darkred",
                                            },
                                        }}
                                    >
                                        <CloseIcon/>
                                    </IconButton>
                                    <div className="card-item__focus"></div>
                                    <div className="card-item__cover">
                                        <img
                                            src={require('../../assets/images/my-payment-options/credit-card.jpg')}
                                            className="card-item__bg"
                                            alt="Credit Card Background"
                                        />
                                    </div>
                                    {!show3DS &&
                                        <>
                                            {!cardType ?
                                                <div className="card-item__wrapper">
                                                    <div className="card-item__card_type">
                                                        <div className="">
                                                            <h2 className="card-type-selector">Select Card Type</h2>
                                                            <div className="card-type-buttons">
                                                                {[
                                                                    {
                                                                        name: 'Visa',
                                                                        logo: visaLogo,
                                                                        height: '10px',
                                                                    },
                                                                    {
                                                                        name: 'MasterCard',
                                                                        logo: mcLogo,
                                                                        height: '25px',
                                                                    },
                                                                    {
                                                                        name: 'Amex',
                                                                        logo: amexLogo,
                                                                        height: '25px',
                                                                    },
                                                                ].map((card_type) => (
                                                                    <button
                                                                        key={card_type}
                                                                        className='btn'
                                                                        onClick={() => setCardType(card_type.name.toLowerCase())}
                                                                    >
                                                                        <img
                                                                            src={card_type.logo} alt="" style={{
                                                                            width: 'auto',
                                                                            height: card_type.height
                                                                        }}/>
                                                                        {card_type.name}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <div className="card-item__wrapper">
                                                        <div className="card-item__top">
                                                        </div>
                                                        <div className="form-inputs">
                                                            <div
                                                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-4">
                                                                <span className="credit-card-label">Card Number</span>
                                                                <input
                                                                    type="text"
                                                                    id="card-number"
                                                                    className="form-control form-control-sm"
                                                                    style={{backgroundColor: '#fff'}}
                                                                    value={cardDetails["card-number"]
                                                                        .replace(/(\d{4})(?=\d)/g, '$1 ')
                                                                        .trim()}
                                                                    onChange={(e) => {
                                                                        const rawValue = e.target.value.replace(/\D/g, '');
                                                                        setCardDetails((prevDetails) => ({
                                                                            ...prevDetails,
                                                                            "card-number": rawValue,
                                                                            "card-prefix": rawValue.slice(0, 4),
                                                                            "card-postfix": rawValue.slice(-4),
                                                                        }));
                                                                    }}
                                                                    placeholder="Card Number"
                                                                    maxLength={19}
                                                                    readOnly={true}
                                                                />
                                                                {errors.cardNumber && (
                                                                    <p className="card-form-error">{errors.cardNumber}</p>
                                                                )}
                                                            </div>

                                                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                <span
                                                                    className="credit-card-label">Expiration Date</span>
                                                                <div className="form-group">
                                                                    <div
                                                                        className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                        <select
                                                                            id="expiry-month"
                                                                            className="form-control"
                                                                            value={cardDetails["expiry-month"]}
                                                                            onChange={handleInputChange}
                                                                            readOnly={true}
                                                                        >
                                                                            <option value="" disabled>
                                                                                Month
                                                                            </option>
                                                                            {Array.from({length: 12}, (_, i) => (
                                                                                <option key={i + 1}
                                                                                        value={String(i + 1).padStart(2, '0')}>
                                                                                    {String(i + 1).padStart(2, '0')}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        {errors.expiryMonth && (
                                                                            <p className="card-form-error">{errors.expiryMonth}</p>
                                                                        )}
                                                                    </div>


                                                                    <div
                                                                        className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                        <select
                                                                            id="expiry-year"
                                                                            className="form-control"
                                                                            value={cardDetails["expiry-year"]}
                                                                            onChange={handleInputChange}
                                                                            readOnly={true}
                                                                        >
                                                                            <option value="" disabled>
                                                                                Year
                                                                            </option>
                                                                            {Array.from({length: 20}, (_, i) => {
                                                                                const currentYear = new Date().getFullYear();
                                                                                return (
                                                                                    <option key={i}
                                                                                            value={String(currentYear + i).slice(-2)}>
                                                                                        {currentYear + i}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                        {errors.expiryYear && (
                                                                            <p className="card-form-error">{errors.expiryYear}</p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">

                                                            </div>

                                                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                                                <span className="credit-card-label">CVV</span>
                                                                <input
                                                                    type="number"
                                                                    id="security-code"
                                                                    required
                                                                    className="form-control"
                                                                    value={cardDetails["security-code"]}
                                                                    placeholder={cardType === 'amex' ? 'XXXX' : 'XXX'}
                                                                    onChange={(e) => {
                                                                        let value;
                                                                        if (cardType === 'amex') {
                                                                            value = e.target.value.slice(0, 3);
                                                                        } else {
                                                                            value = e.target.value.slice(0, 2);
                                                                        }
                                                                        handleInputChange({
                                                                            target: {
                                                                                name: 'CVV',
                                                                                value
                                                                            }
                                                                        });
                                                                    }}
                                                                    readOnly={true}/>
                                                                {errors.securityCode && (
                                                                    <p className="card-form-error">{errors.securityCode}</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-item__action card-item__bottom__section">
                                                        <Box display="flex" gap={2}> {/* Adjust gap as needed */}
                                                            <Button
                                                                variant="contained"
                                                                color="error"
                                                                onClick={() => setCardType(null)}
                                                            >
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => {
                                                                    window.PaymentSession.updateSessionFromForm('card')
                                                                }}
                                                            >
                                                                {saveCard == 'Y' ? 'Save' : 'Proceed'}
                                                            </Button>
                                                        </Box>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Snackbar
                    open={openAlert}
                    autoHideDuration={3000}
                    onClose={handleAlertClose}
                >
                    <Alert
                        onClose={handleAlertClose}
                        severity={severity}
                        sx={{width: '100%'}}
                    >
                        {errMsg}
                    </Alert>
                </Snackbar>
            </BlockUi>
        </div>


    );
};

export default AddCardForm;
